import * as React from "react";
import sideLogo from "../images/side-logo.svg";
import "../sass/styles.scss";
import { Link } from "gatsby";

const SignUpPage = () => {
  return (
    <div>
      <title>Twadel | Signup</title>
      <div className="c-sign-up-container">
        <div className="c-sign-up-container__layout">
          <img
            className="c-sign-up-container__logo"
            src={sideLogo}
            alt="twadel-logo"
          />
          <div className="c-sign-up-container__message">Launching soon</div>
          <Link
            to="/"
            className="c-signings__button c-signings__button--sign-up"
          >
            Home
            {rightArrow()}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;

function rightArrow() {
  return (
    <svg
      class="HoverArrow"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      aria-hidden="true"
    >
      <g fill-rule="evenodd">
        <path class="HoverArrow__linePath" d="M0 5h7"></path>
        <path class="HoverArrow__tipPath" d="M1 1l4 4-4 4"></path>
      </g>
    </svg>
  );
}
